import { React } from "react";
import { Marker, Popup } from "react-leaflet";
import L from 'leaflet'
import arbol from '../images/arbol.jpeg'
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import redPin from '../images/marcador-40-28-red.png';
import bluePin from '../images/pin-de-ubicacion.png';
import greenPin from '../images/marcador-40-28-green.png';
import { handleClick } from "./Utils";
import { formatThousands } from '../utils/Utils';
import { useTranslation } from 'react-i18next'
import { productUrl } from "./Utils";
import { providerUrl } from "./Utils";



import circle from '../images/location.svg'
import owner from '../images/userProvider.svg'

import category from '../images/categoryIconVerde.svg'
import forestT from '../images/iconTreesVerde.svg'


function MarkerLayer({ productsData, categories }) {
  const { t } = useTranslation();
  const products = productsData;




  const customMarker = (category) => {
    let color;
    switch (category) {
      case 'Forestal':
        color = greenPin;
        break;
      case '':
        color = redPin;
        break;
      case 'Acuíferos':
        color = bluePin;
        break;
      default:
        color = bluePin;
        break;
    }
    return (
      new L.icon({
        iconUrl: color,
        iconSize: [30, 30],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40]
      })
    )
  }

  


  return (
    products.map((product) => {
      const coords = new Array(2);
      coords[0] = Number(product.latitude);
      coords[1] = Number(product.longitude);

      return (
        <Marker key={product.id} position={coords} icon={customMarker(product.category)}>
          <Popup maxWidth="120px">
            <Card key={product.id} style={{ border: 'none' }} className=" flex col-md-1 mx-auto card-container-map shadow-lg rounded-sm border border-principal">
              {/* Imagen */}
              <div className='card-img-map ml-3 mr-3'>
                {product.productImageDtoList.length === 0 ?
                  <Card.Img className='mt-3 card-img-catalogo-map' variant="top" src={arbol} />
                  :
                  product.productImageDtoList.map((y, index) =>
                    y.principal === true ?
                      <Card.Img key={index} className='mt-3 card-img-catalogo-map' variant="top" src={y.path} />
                      :
                      ""
                  )}
              </div>
              <Card.Body>
                {/* Nombre del monte */}
                <Link onClick={handleClick} to={productUrl(product)}>
                  <span className='text-airco2-filter-title flex text-lg'>
                    <span>{product.name}</span>
                  </span>
                </Link>

                {/* Datos del monte */}
                <Card.Text>
                  <span>
                    <ul className='mt-1 space-y-1'>
                      <li className=''>
                        <Link onClick={handleClick} to={providerUrl(product)} className="text-airco2-filter-content">
                          <span className='flex gap-1'>
                            <img alt='circulo-icono' src={owner} width={'16px'} ></img>
                            <span className="mt-1">{product.provider}</span>
                          </span>
                        </Link>
                      </li>
                      <li className=''>
                        <span className='flex'>
                          <img alt='circulo-icono' src={circle} width={'16px'} ></img>
                          <a href={`https://maps.google.com/?q=${product.latitude},${product.longitude}`} aria-label='redirige a googleMaps' target='_blank' rel='noopener noreferrer'
                            className='text-airco2-filter-content '>
                            <span className='mx-2'> {product.city}, {product.state}</span>
                          </a>
                        </span>
                      </li>
                      <li>
                        <span className='flex'>
                          <img alt='circulo-icono' src={forestT} width={'16px'} ></img>
                          <span className=' forestype-min mx-2 text-airco2-filter-content'>{product.forestType}</span>
                        </span>
                      </li>
                      <li>
                        {(product.category)&&
                        <span className='flex'>
                          <img alt='circulo-icono' src={category} width={'16px'} ></img>
                          <span className=' category-min mx-2 text-airco2-filter-content'> {product.category}</span>
                        </span>
                        }
                      </li>
                      <li className='text-airco2-filter-content font-semibold'>
                        {formatThousands(product.price)}
                        <span className="font-normal" style={{ fontSize: '11px' }}> €/{t("detail.tonelada")}</span>
                      </li>
                      <li className='text-airco2-filter-content font-semibold'>
                      {formatThousands(product.stockCredits - product.reservedCredits)}
                        <span className="font-normal" style={{ fontSize: '11px' }}> {t("detail.toneladaD")}</span>
                      </li>
                    </ul>
                  </span>
                </Card.Text>
              </Card.Body>
            </Card >
          </Popup>
        </Marker>
      )
    })
  )
}
export default MarkerLayer;